import React from "react";
import {
    FaFacebook,
    FaYoutube,
    FaTwitter,
    FaInstagram,
    FaLinkedin,
    FaPhoneAlt,
    FaEnvelope,
    FaHome, FaCodeBranch,
} from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const Footer = () => {

    const navigate = useNavigate();
    return (
        <footer className="text-center lg:text-left bg-sct-secondary text-white">
            <div className="mx-6 py-10 text-center md:text-left">
                <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <div className="cursor-pointer ">
                        <img src={require("../../assets/images/sct-logo-white.png")}/>

                        <div className="flex justify-center items-center flex-col">
                            {/*<p className="uppercase font-semibold mb-2 flex justify-center md:justify-start">
                                Verified By
                            </p>*/}
                            <img  src={require("../../assets/images/verified.png")} width={130}/>
                            <div className="mx-6 py-10 text-center">
                                <ul className="flex flex-1 items-center gap-2 md:gap-5">
                                    <li className="cursor-pointer social-btn facebook-btn">
                                        {/*<a href="https://www.facebook.com/profile.php?id=100088335932106"*/}
                                        {/*   target="_blank">*/}
                                        <a className="visited:decoration-current" href="https://www.facebook.com/profile.php?id=100088335932106"
                                           target="_blank">
                                        <FaFacebook className="text-2xl"/>
                                        </a>
                                        {/*</a>*/}
                                    </li>
                                    {/* <li className="cursor-pointer social-btn youtube-btn">
                  <FaYoutube className="text-2xl" />
                </li> */}
                                    <li className="cursor-pointer social-btn twitter-btn">
                                        <FaTwitter className="text-2xl"/>
                                    </li>
                                    {/* <li className="cursor-pointer social-btn instagram-btn">
                  <FaInstagram className="text-2xl" />
                </li> */}
                                    <li className="cursor-pointer social-btn linkedin-btn">
                                       <a className="visited:decoration-current" href="https://www.linkedin.com/company/source-code-technologies-llc/"
                                           target="_blank">
                                        <FaLinkedin className="text-2xl"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div>
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Products
                        </h6>
                        <p className="mb-4">
                            <a
                                className="text-white cursor-pointer hover:font-bold"
                                onClick={() => navigate("products/shopzilla")}
                            >
                                Shopzilla
                            </a>
                        </p>
                        <p className="mb-4">
                            <a
                                className="text-white cursor-pointer hover:font-bold"
                                onClick={() => navigate("products/i-scrumai")}
                            >
                                iScrum AI
                            </a>
                        </p>
                        <p className="mb-4">
                            <a
                                className="text-white cursor-pointer hover:font-bold"
                                onClick={() => navigate("products/datrix")}
                            >
                                DatriX
                            </a>
                        </p>
                        <p className="mb-4">
                            <a
                                className="text-white cursor-pointer hover:font-bold"
                                onClick={() => navigate("products/investify")}
                            >
                                Investify
                            </a>
                        </p>
                    </div>
                    <div>
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Other links
                        </h6>
                        <p className="mb-4">
                            <a
                                className="text-white cursor-pointer hover:font-bold"
                                onClick={() => navigate("terms-and-conditions")}
                            >
                                Terms and conditions
                            </a>
                        </p>
                        <p className="mb-4">
                            <a
                                className="text-white cursor-pointer hover:font-bold"
                                onClick={() => navigate("privacy-statement")}
                            >
                                Privacy Statement
                            </a>
                        </p>
                        <p className="mb-4">
                            <a
                                className="text-white cursor-pointer hover:font-bold"
                                onClick={() => navigate("cookie-declaration")}
                            >
                                Cookie declaration
                            </a>
                        </p>
                        {/* <p>
              <a
                className="text-white cursor-pointer hover:font-bold"
                onClick={() => navigate("copyright-policy")}
              >
                Copyright Policy
              </a>
            </p> */}
                    </div>
                    <div>
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Headquarters
                        </h6>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            {/*177 Cadence, Irvine, <br/>Orange County, CA 92618.*/}
                            26000 Towne Center Dr N, Suite # 210,<br/>Lake Forest, CA 92610.
                        </p>

                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Austin Office
                        </h6>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            9900 Spectrum Dr, <br/> Austin,TX 78717.
                        </p>
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Colorado Office
                        </h6>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            121 S. Tejon Street, Suite 201,<br/>Colorado Springs, CO 80903.
                        </p>
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Michigan Office
                        </h6>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            336 W. First Street, Suite 113,<br/>Flint, MI 48502.
                        </p>
                        <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                            Contact Info
                        </h6>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            <FaEnvelope className="text-xl mr-4"/>
                            admin@sourcecodetecno.com
                        </p>
                        <p className="flex items-center justify-center md:justify-start mb-4">
                            <FaPhoneAlt className="text-xl mr-4"/>
                            949.344.4069 <br/> 949.822.7011
                        </p>
                    </div>
                </div>
            </div>
            <div className="text-center p-6 bg-sct-primary text-white">
        <span onClick={() => navigate("copyright-policy")}>
          © 2025 Copyright:
        </span>
                <a className="text-white font-semibold">
                    &nbsp; Source Code Technologies LLC. All rights reserved.
                </a>
            </div>
        </footer>
    );
};

export default Footer;
