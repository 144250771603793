import React from "react";

const ModelBox = (props) => {
  return (
    <div
      className={`min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover ${
        props.hidden ? "hidden" : ""
      }`}
      id="modal-id"
    >
      <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
      <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
        <div className="">
          <div className="text-center p-5 flex-auto justify-center items-center flex flex-col">
            {props.icon}
            <h2 className="text-xl font-bold py-4 ">{props.title}</h2>
            <p className="text-sm text-gray-500 px-8">{props.subTitle}</p>
          </div>

          <div className="p-3  mt-2 text-center space-x-4 md:block">
            {props.onCancel && (
              <button className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100">
                Cancel
              </button>
            )}
            {props.onOkay && (
              <button
                className="mb-2 md:mb-0 bg-sct-primary px-8 py-3 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-sct-secondary"
                onClick={props.onOkay}
              >
                Close
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelBox;
